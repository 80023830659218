export const API_ROUTES = {
    LOGIN: '/users/auth/verify/',
    DEALER_LOGIN: '/users/auth/dealer_verify/',
    REALTIME_TRACTOR_DATA: '/tractor/get_fleet_data/', // JWT Added
    OTP_REQUEST: '/users/auth/login/',
    DAY_SUMMARY_DATA: '/data/summary/day/',
    DAY_DETAIL_DATA: '/data/details/day/',
    TRIP_DATES: '/data/count/',
    NOTIFICATIONS: '/alerts/tractor/',
    GROMAX_DEVICES: '/devices/get/gromax_devices/',
    IMMOBILIZE_DEVICE: '/devices/send_immobilize/',
    TRACTOR_COMPANY: '/tractor/makes/',
    GET_ALL_GEOFENCE: '/fleet/get_all_geofences/',
    EDIT_GEOFENCE: '/fleet/edit_geofence/',
    TODAY_STATS: '/fleet/get_overall_status/', // JWT Added
    DAY_EVENTS: '/data/get_replay_event/',
    TODAY_DEALER_STATS: '/fleet/dealers_overall_status/', // JWT Added
    DEALER_REALTIME_TRACTOR_DATA: '/fleet/dealer_fleet_data/',
    REFRESH_ACCESS_TOKEN: '/users/refresh_access_token/',
}

export const API_BASE_URL = {
    PARENT_URL: process.env.REACT_APP_PARENT_URL
        ? process.env.REACT_APP_PARENT_URL
        : 'https://production.carnot-traclytics.com',
}
