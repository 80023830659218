import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { motion } from 'framer-motion'
import { connect } from 'react-redux'
import { tripTimeFromDaySummary, getTimeStatusDiff } from '../utils/TimeUtils'
import { tractorRequest } from '../store/actions/tractorAction'
import { daySummaryRequest } from '../store/actions/daySummaryAction'
import AreaIcon from '../assets/area_icon.svg'
import LandingTime from '../assets/landing_time.svg'
import getTractorImages from '../utils/getTractorImages'
import NoNetwork from '../assets/no_network.svg'

const useStyles = makeStyles((theme) => ({
    tractor: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '1em',
        margin: '0 0 1em 0',
        position: 'relative',
        zIndex: '4',
        cursor: 'pointer',
    },
    tractorDisc: {
        flex: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        cursor: 'pointer',
        '& > div': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    tractorActive: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        borderRadius: '4px',
        zIndex: '4',
        padding: '10px',
    },
    selectTractorDisc: {
        flex: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        cursor: 'pointer',
        margin: '5px 10px 5px 0px',
        '& > div': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    engineOff: {
        position: 'relative',
        width: '90px',
        height: '90px',
        borderRadius: '8px',
        background: '#F1F1F1',
        display: 'grid',
        placeContent: 'center',
        margin: '5px 5px 5px 5px',
        border: '2px solid #FF0C22',
        '& > img': {
            width: '80%',
        },
    },
    engineOn: {
        position: 'relative',
        width: '90px',
        height: '90px',
        borderRadius: '8px',
        background: '#F1F1F1',
        display: 'grid',
        placeContent: 'center',
        margin: '5px 5px 5px 5px',
        border: '2px solid #00c441',
        '& > img': {
            width: '80%',
        },
    },
    engineOut: {
        position: 'relative',
        width: '90px',
        height: '90px',
        borderRadius: '8px',
        background: '#F1F1F1',
        display: 'grid',
        placeContent: 'center',
        margin: '5px 5px 5px 5px',
        border: '2px solid #808080',
        '& > img': {
            width: '80%',
        },
    },
    makeModel: {
        margin: '0 0 0.5em 0',
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '19px',
        color: '#333333',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flex: '1',
    },
    tractorNameText: {
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '16px',
        color: '#666666',
    },
    imageBorderOut: {
        position: 'absolute',
        right: '0',
        height: '27px',
        padding: '3px',
        background: '#fff',
        borderRadius: '0px 8px 0px 8px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    imageBorderOn: {
        position: 'absolute',
        right: '0',
        height: '20px',
        padding: '3px',
        background: '#00c441',
        borderRadius: '0px 0px 0px 8px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    imageBorderOff: {
        position: 'absolute',
        right: '0',
        height: '20px',
        padding: '3px',
        background: '#FF0C22',
        borderRadius: '0px 0px 0px 8px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

function Tractor({
    tractor,
    show,
    setShow,
    position,
    setSelectedTractor,
    index,
    realTime,
    onPositionChange,
    onShowTrips,
    setShowAreaButton,
    drawingTool,
    setDrawingTool,
    shapes,
    setShapes,
    ...props
}) {
    const classes = useStyles()
    const tractorImage = getTractorImages(tractor.model, tractor.make)

    const checkEngineStatus = (stat) => {
        let value = getTimeStatusDiff(
            props.checkRealtime.data[`${tractor.device_pk}`]['time_stamp']
        )
        if ((stat && 1) === 1) {
            if (value > 10) {
                return classes.engineOut
            } else {
                return classes.engineOn
            }
        } else if ((stat && 1) === 0) {
            if (value > 120) {
                return classes.engineOut
            } else {
                return classes.engineOff
            }
        }
    }

    const checkEngine = (stat) => {
        let value = getTimeStatusDiff(
            props.checkRealtime.data[`${tractor.device_pk}`]['time_stamp']
        )
        if ((stat && 1) === 1) {
            if (value > 10) {
                return classes.imageBorderOut
            } else {
                return classes.imageBorderOn
            }
        } else if ((stat && 1) === 0) {
            if (value > 120) {
                return classes.imageBorderOut
            } else {
                return classes.imageBorderOff
            }
        }
    }

    const checkEngineText = (stat) => {
        let value = getTimeStatusDiff(
            props.checkRealtime.data[`${tractor.device_pk}`]['time_stamp']
        )
        if ((stat && 1) === 1) {
            if (value > 10) {
                return 'DIS'
            } else {
                return 'ON'
            }
        } else if ((stat && 1) === 0) {
            if (value > 120) {
                return 'DIS'
            } else {
                return 'OFF'
            }
        }
    }

    const getName = () => {
        if (props.localTracName) {
            for (let i = 0; i < props.localTracName.length; i++) {
                if (props.localTracName[i].id === tractor.id) {
                    return props.localTracName[i].name.toLowerCase()
                }
            }
        }
        return tractor.name.toLowerCase()
    }

    return (
        <div
            className={classes.tractor}
            onClick={() => {
                onPositionChange(index)
                setSelectedTractor((prev) => {
                    props.selectTractor({ tractor, realTime })
                    props.daySummary({ tractor, realTime })
                    setShow(false)
                    onShowTrips(false)
                    setShowAreaButton(false)
                    if (drawingTool) {
                        drawingTool.setMap(null)
                    }
                    if (shapes && shapes.length > 0) {
                        for (let i = 0; i < shapes.length; i++) {
                            shapes[i].overlay.setMap(null)
                        }
                    }
                    return { tractor, index, realTime }
                })
            }}
        >
            {show && (
                <>
                    <motion.div
                        className={`${
                            props.checkRealtime &&
                            checkEngineStatus(
                                props.checkRealtime.data[
                                    `${tractor.device_pk}`
                                ]['status']
                            )
                        }`}
                    >
                        <div
                            className={`${
                                props.checkRealtime &&
                                checkEngine(
                                    props.checkRealtime.data[
                                        `${tractor.device_pk}`
                                    ]['status']
                                )
                            }`}
                        >
                            <p>
                                {props.checkRealtime &&
                                checkEngineText(
                                    props.checkRealtime.data[
                                        `${tractor.device_pk}`
                                    ]['status']
                                ) === 'DIS' ? (
                                    <img
                                        src={NoNetwork}
                                        alt="disconnected"
                                        width="20px"
                                        height="20px"
                                    />
                                ) : (
                                    props.checkRealtime &&
                                    checkEngineText(
                                        props.checkRealtime.data[
                                            `${tractor.device_pk}`
                                        ]['status']
                                    )
                                )}
                            </p>
                        </div>
                        <motion.img
                            animate
                            src={
                                tractor.tractor_img_url !== null
                                    ? tractor.tractor_img_url
                                    : tractorImage
                            }
                            alt="tractor"
                            width="80px"
                        />
                    </motion.div>
                    <motion.div
                        className={
                            index !== position
                                ? classes.tractorDisc
                                : classes.selectTractorDisc
                        }
                        transition={{ duration: 0.3 }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        {props.checkRealtime && (
                            <div style={{ display: 'flex' }}>
                                <div className={classes.makeModel}>
                                    {tractor.make.toLowerCase()}{' '}
                                    {tractor.model.toLowerCase()}
                                </div>
                            </div>
                        )}
                        {props.checkRealtime && (
                            <>
                                <motion.div style={{ display: 'flex' }}>
                                    <motion.div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            gap: '10px',
                                            flex: '1',
                                        }}
                                    >
                                        <motion.img
                                            animate
                                            src={LandingTime}
                                            alt="landing-time"
                                        />
                                        <span>
                                            <b>
                                                {tripTimeFromDaySummary(
                                                    props.checkRealtime.data[
                                                        `${tractor.device_pk}`
                                                    ]['time']
                                                )}
                                            </b>
                                            <br></br>Worked
                                        </span>
                                    </motion.div>
                                    <motion.div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            gap: '10px',
                                            flex: '1',
                                        }}
                                    >
                                        <motion.img
                                            animate
                                            src={AreaIcon}
                                            alt="landing-area"
                                        />
                                        <span>
                                            <b>
                                                {props.checkRealtime.data[
                                                    `${tractor.device_pk}`
                                                ]['acres'].toFixed(2)}
                                            </b>
                                            <br></br>Acres
                                        </span>
                                    </motion.div>
                                </motion.div>
                            </>
                        )}
                        <div className={classes.tractorNameText}>
                            <span>{getName()}</span>
                        </div>
                        {index === position && (
                            <motion.div
                                layoutId="tractor-active"
                                className={classes.tractorActive}
                            />
                        )}
                    </motion.div>
                </>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    checkRealtime: state.realtimeReducer.realtimeData,
    localTracName: state.localTractorNameReducer.localTractorNameList,
})

const mapDispatchToProps = (dispatch) => ({
    selectTractor: ({ tractor, realTime }) => {
        dispatch(tractorRequest({ tractor, realTime }))
    },
    daySummary: ({ realTime, tractor, date }) => {
        dispatch(daySummaryRequest({ realTime, tractor, date }))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Tractor)
