import axios from 'axios'
import { parseDayDetails } from '../utils/MapUtils'
import { API_BASE_URL, API_ROUTES } from '../apiConfig'
import store from '../store'
import { realtimeRequest } from '../store/actions/realtimeRequestAction'
import { getTodayStatusRequest } from '../store/actions/getTodayStatusAction'
import { getDayEventsRequest } from '../store/actions/getDayEventsAction'
import { getAllGeofenceRequest } from '../store/actions/getAllGeofenceAction'
import { editGeofenceRequest } from '../store/actions/editGeofenceAction'
import { fetchNotificationRequest } from '../store/actions/fetchNotificationAction'
import { tractorCompanyRequest } from '../store/actions/tractorCompanyAction'
import { tractorModelRequest } from '../store/actions/tractorModelAction'
import { tractorRequest } from '../store/actions/tractorAction'
import { daySummaryRequest } from '../store/actions/daySummaryAction'

const headerParams = {
    'app-build': 'fleet',
    'app-version': '1.0.2',
    platform: 'web',
}
axios.defaults.baseURL = 'https://production.carnot-traclytics.com'

export const fetchTractorListData = async ({ phoneNumber, otp }) => {
    const demoNumbers = ['9423049272', '9634200177', '9627823355', '9628404040']

    if (demoNumbers.includes(phoneNumber) && otp === 1059) {
        otp = '6145'
    }
    try {
        if (sessionStorage.getItem('user_type') === 'dealer') {
            const { data } = await axios.post(
                `${API_BASE_URL.PARENT_URL}${API_ROUTES.DEALER_LOGIN}`,
                {
                    phone: phoneNumber,
                    otp: otp,
                }
            )

            return data
        } else {
            const { data } = await axios.post(
                `${API_BASE_URL.PARENT_URL}${API_ROUTES.LOGIN}`,
                {
                    phone: phoneNumber,
                    otp: otp,
                }
            )
            return data
        }
    } catch (error) {
        throw error
    }
}

export const fetchRealtimeTractorData = async ({ phone }) => {
    try {
        if (sessionStorage.getItem('user_type') === 'dealer') {
            const { data } = await axios.post(
                `${API_BASE_URL.PARENT_URL}${API_ROUTES.DEALER_REALTIME_TRACTOR_DATA}`,
                {
                    phone: phone,
                },
                {
                    headers: {
                        ...headerParams,
                        'User-Auth': `Bearer ${sessionStorage.getItem(
                            'access_token'
                        )}`,
                    },
                }
            )
            return data
        } else {
            const { data } = await axios.post(
                `${API_BASE_URL.PARENT_URL}${API_ROUTES.REALTIME_TRACTOR_DATA}`,
                {
                    phone: phone,
                },
                {
                    headers: {
                        ...headerParams,
                        'User-Auth': `Bearer ${sessionStorage.getItem(
                            'access_token'
                        )}`,
                    },
                }
            )
            return data
        }
    } catch (error) {
        if (error?.response.status === 401) {
            await getRefreshAccessToken(realtimeRequest, { phone })
        }
        throw error
    }
}

export const makeOTPRequestCall = async (phoneNumber) => {
    try {
        let debugMode = false
        if (
            process.env.REACT_APP_DEBUG_MODE &&
            process.env.REACT_APP_DEBUG_MODE === 'true'
        ) {
            debugMode = true
        }
        const { data } = await axios.post(
            `${API_BASE_URL.PARENT_URL}${API_ROUTES.OTP_REQUEST}`,
            {
                phone: phoneNumber,
                debug: debugMode,
            }
        )
        return data
    } catch (error) {
        throw error
    }
}

export const fetchDaySummary = async ({
    deviceId,
    date,
    tractor,
    realTime,
}) => {
    try {
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let today = date.getDate()
        if (month < 10) {
            month = '0' + month
        }

        if (today < 10) {
            today = '0' + today
        }

        const { data } = await axios.get(
            `${API_BASE_URL.PARENT_URL}${API_ROUTES.DAY_SUMMARY_DATA}${deviceId}/${year}/${month}/${today}/0/`,
            {
                headers: {
                    ...headerParams,
                    'User-Auth': `Bearer ${sessionStorage.getItem(
                        'access_token'
                    )}`,
                },
            }
        )

        return data
    } catch (error) {
        if (error?.response.status === 401) {
            await getRefreshAccessToken(daySummaryRequest, {
                tractor,
                realTime,
                date,
            })
        }
        throw error
    }
}

export const fetchDayDetail = async ({ deviceId, date, tractor, realTime }) => {
    try {
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let today = date.getDate()

        if (month < 10) {
            month = '0' + month
        }

        if (today < 10) {
            today = '0' + today
        }

        const {
            data: { data },
        } = await axios.get(
            `${API_BASE_URL.PARENT_URL}${API_ROUTES.DAY_DETAIL_DATA}${deviceId}/${year}/${month}/${today}/0/`,
            {
                headers: {
                    ...headerParams,
                    'User-Auth': `Bearer ${sessionStorage.getItem(
                        'access_token'
                    )}`,
                },
            }
        )
        return parseDayDetails(data)
    } catch (error) {
        if (error?.response.status === 401) {
            await getRefreshAccessToken(daySummaryRequest, {
                tractor,
                realTime,
                date,
            })
        }
        throw error
    }
}

export const fetchTripsDates = async ({ deviceId, tractor, realTime }) => {
    try {
        let date = new Date()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let today = date.getDate()

        if (month < 10) {
            month = '0' + month
        }

        if (today < 10) {
            today = '0' + today
        }

        const {
            data: { data },
        } = await axios.get(
            `${API_BASE_URL.PARENT_URL}${API_ROUTES.TRIP_DATES}${deviceId}/${year}/${month}/${today}/180/`,
            {
                headers: {
                    ...headerParams,
                    'User-Auth': `Bearer ${sessionStorage.getItem(
                        'access_token'
                    )}`,
                },
            }
        )
        return data
    } catch (error) {
        if (error?.response.status === 401) {
            await getRefreshAccessToken(tractorRequest, {
                tractor,
                realTime,
            })
        }
        throw error
    }
}

export const fetchAddressFromPoints = async (points) => {
    const names = []
    for (let point of points) {
        const {
            data: { display_name },
        } = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${point.realtime.latitude}&lon=${point.realtime.longitude}`
        )
        names.push(display_name)
    }

    return names
}

export const fetchNotification = async ({ id }) => {
    // const notify;
    try {
        const { data } = await axios.get(
            `${API_BASE_URL.PARENT_URL}${API_ROUTES.NOTIFICATIONS}${id}/`,
            {
                headers: {
                    ...headerParams,
                    'User-Auth': `Bearer ${sessionStorage.getItem(
                        'access_token'
                    )}`,
                },
            }
        )
        return data
    } catch (error) {
        if (error?.response.status === 401) {
            await getRefreshAccessToken(fetchNotificationRequest, {
                id,
            })
        }
        throw error
    }
}

export const checkPasswordForImmobilize = ({ password }) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (password.toString() === '12345678') {
                resolve({ status: true, msg: 'Password Matched!!' })
            } else {
                reject({ status: false, msg: 'Incorrect Password!!' })
            }
        }, 1500)
    })
}

export const getGromaxDevices = async () => {
    try {
        const { data } = await axios.get(
            `${API_BASE_URL.PARENT_URL}${API_ROUTES.GROMAX_DEVICES}`
        )
        return data
    } catch (e) {
        throw e
    }
}

export const immobalizeDevice = async ({ id }) => {
    try {
        const { data } = await axios.get(
            `${API_BASE_URL.PARENT_URL}${API_ROUTES.IMMOBILIZE_DEVICE}${id}/`
        )
        return data
    } catch (e) {
        throw e
    }
}

//Update Tractor Name
export const updateTractorData = async ({ requestTractorDetail }) => {
    try {
        const { data } = await axios.post(
            `/tractor/${requestTractorDetail.id}/details/`,
            requestTractorDetail
        )
        return data
    } catch (error) {
        throw error
    }
}

export const satellitePatchData = async ({ requestPatch }) => {
    try {
        const { data } = await axios({
            method: 'post',
            url: `https://4gv48ymxwc.execute-api.us-east-2.amazonaws.com/dev/satellite/{patch_area}/{boundary_points}`,
            data: requestPatch,
        })
        return data
    } catch (error) {
        throw error
    }
}

export const tractorCompanyAPI = async ({ requestTractorId }) => {
    try {
        const { data } = await axios({
            method: 'get',
            url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.TRACTOR_COMPANY}${requestTractorId.deviceId}/`,
            headers: {
                ...headerParams,
                'User-Auth': `Bearer ${sessionStorage.getItem('access_token')}`,
            },
        })
        return data
    } catch (error) {
        if (error?.response.status === 401) {
            await getRefreshAccessToken(tractorCompanyRequest, {
                requestTractorId,
            })
        }
        throw error
    }
}

export const tractorModelAPI = async ({ requestTractorModelId }) => {
    try {
        const { data } = await axios({
            method: 'get',
            url: `${API_BASE_URL.PARENT_URL}/tractor/${requestTractorModelId.company}/models/${requestTractorModelId.deviceId}/`,
            headers: {
                ...headerParams,
                'User-Auth': `Bearer ${sessionStorage.getItem('access_token')}`,
            },
        })
        return data
    } catch (error) {
        if (error?.response.status === 401) {
            await getRefreshAccessToken(tractorModelRequest, {
                requestTractorModelId,
            })
        }
        throw error
    }
}

export const getAllGeofence = async ({ requestTractorId }) => {
    try {
        const { data } = await axios({
            method: 'get',
            url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.GET_ALL_GEOFENCE}${requestTractorId.deviceId}/`,
            headers: {
                ...headerParams,
                'User-Auth': `Bearer ${sessionStorage.getItem('access_token')}`,
            },
        })
        return data
    } catch (error) {
        if (error?.response.status === 401) {
            await getRefreshAccessToken(getAllGeofenceRequest, {
                requestTractorId,
            })
        }
        throw error
    }
}

export const editGeofenceAPI = async ({ requestEditGeofence }) => {
    try {
        let deviceId = requestEditGeofence.deviceId
        delete requestEditGeofence['deviceId']
        const { data } = await axios({
            method: 'post',
            url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.EDIT_GEOFENCE}${deviceId}/`,
            data: requestEditGeofence,
            headers: {
                ...headerParams,
                'User-Auth': `Bearer ${sessionStorage.getItem('access_token')}`,
            },
        })
        return data
    } catch (error) {
        if (error?.response.status === 401) {
            await getRefreshAccessToken(editGeofenceRequest, {
                requestEditGeofence,
            })
        }
        throw error
    }
}

export const getTodayStatusAPI = async ({ requestUserID }) => {
    try {
        if (sessionStorage.getItem('user_type') === 'dealer') {
            const { data } = await axios({
                method: 'post',
                url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.TODAY_DEALER_STATS}`,
                data: requestUserID,
                headers: {
                    'User-Auth': `Bearer ${sessionStorage.getItem(
                        'access_token'
                    )}`,
                },
            })
            return data
        } else {
            const { data } = await axios({
                method: 'post',
                url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.TODAY_STATS}`,
                data: requestUserID,
                headers: {
                    ...headerParams,
                    'User-Auth': `Bearer ${sessionStorage.getItem(
                        'access_token'
                    )}`,
                },
            })
            return data
        }
    } catch (error) {
        if (error?.response.status === 401) {
            await getRefreshAccessToken(getTodayStatusRequest, {
                requestUserID,
            })
        }
        throw error
    }
}

export const getDayEventsAPI = async ({ requestDeviceID }) => {
    try {
        const { data } = await axios({
            method: 'post',
            url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.DAY_EVENTS}`,
            // url: `https://traclytics-staging.herokuapp.com${API_ROUTES.DAY_EVENTS}`,
            data: requestDeviceID,
            headers: {
                ...headerParams,
                'User-Auth': `Bearer ${sessionStorage.getItem('access_token')}`,
            },
        })
        return data
    } catch (error) {
        if (error?.response.status === 401) {
            await getRefreshAccessToken(getDayEventsRequest, {
                requestDeviceID,
            })
        }
        throw error
    }
}

export const getRefreshAccessToken = async (callback, params) => {
    try {
        const { data } = await axios({
            method: 'post',
            url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.REFRESH_ACCESS_TOKEN}`,
            headers: {
                ...headerParams,
                'User-Auth': `Bearer ${sessionStorage.getItem(
                    'refresh_token'
                )}`,
            },
        })
        sessionStorage.setItem('access_token', data.access_token)
        store.dispatch(callback(params))
    } catch (error) {
        sessionStorage.setItem('loggedIn', false)
        sessionStorage.removeItem('phoneNumber')
        sessionStorage.removeItem('user_type')
        sessionStorage.removeItem('access_token')
        sessionStorage.removeItem('refresh_token')
        window.location.reload()
        throw error
    }
}
