import * as types from '../types'

const initialState = {
    loading: false,
    isLoggedIn: false,
    status: null,
    message: null,
    id: null,
    name: null,
    new_user: null,
    phone: null,
    support_no: null,
    tractors: null,
    allTractors: null,
    realTimes: null,
    allRealTimes: null,
    dob: null,
    auth_token: null,
    mm_is_reg: null,
    mm_phone: null,
    mm_userid: null,
    error: {},
    filterModels: null,
    filterMakers: null,
    filterNames: null,
    filtered: false,
    updateLoading: false,
}

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: {},
            }
        case types.LOGIN_REQUEST_SUCCESS:
            sessionStorage.setItem('loggedIn', 'true')
            sessionStorage.setItem('phoneNumber', action.payload.data.phone)
            return {
                ...state,
                loading: false,
                isLoggedIn: true,
                status: action.status,
                message: action.message,
                id: action.payload.data.id,
                name: action.payload.data.name,
                new_user: action.payload.data.new_user,
                phone: action.payload.data.phone,
                support_no: action.payload.data.support_no,
                tractors: action.payload.data.tractors,
                allTractors: action.payload.data.tractors,
                dob: action.payload.data.dob,
                auth_token: action.payload.data.auth_token,
                mm_is_reg: action.payload.data.mm_is_reg,
                mm_phone: action.payload.data.mm_phone,
                mm_userid: action.payload.data.mm_userid,
                realTimes: action.realTimes,
                allRealTimes: action.realTimes,
                error: {},
            }
        case types.UPDATE_REQUEST:
            return {
                ...state,
                updateLoading: true,
            }

        case types.UPDATE_SUCCESS:
            return {
                ...state,
                updateLoading: false,
                realTimes: state.realTimes.map((rt) =>
                    rt.fb_key === action.payload.realTime.fb_key
                        ? action.payload.realTime
                        : rt
                ),
                allRealTimes: state.allRealTimes.map((rt) =>
                    rt.fb_key === action.payload.realTime.fb_key
                        ? action.payload.realTime
                        : rt
                ),
            }

        case types.LOGIN_REQUEST_FAILURE:
            sessionStorage.removeItem('loggedIn')
            sessionStorage.removeItem('phoneNumber')
            return {
                ...state,
                loading: false,
                isLoggedIn: false,
                error: action.error,
            }
        case types.LOGOUT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case types.LOGOUT_REQUEST_SUCCESS:
            sessionStorage.removeItem('loggedIn')
            sessionStorage.removeItem('phoneNumber')
            sessionStorage.removeItem('user_type')
            sessionStorage.removeItem('access_token')
            sessionStorage.removeItem('refresh_token')
            return {
                ...state,
                loading: false,
                isLoggedIn: false,
                status: null,
                message: null,
                id: null,
                name: null,
                new_user: null,
                phone: null,
                support_no: null,
                tractors: null,
                allTractors: null,
                realTimes: null,
                allRealTimes: null,
                dob: null,
                auth_token: null,
                mm_is_reg: null,
                mm_phone: null,
                mm_userid: null,
                error: {},
            }
        case types.LOGOUT_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        case types.FILTER_TRACTOR_REQUEST:
            return {
                ...state,
                filterModels: action.payload.models,
                filterMakers: action.payload.makers,
                filterNames: action.payload.names,
            }
        case types.FILTER_TRACTOR_SUCCESS:
            return {
                ...state,
                tractors: action.payload.tractors,
                realTimes: action.payload.realTimes,
                filtered: true,
            }
        case types.RESET_ALL_FILTERS:
            return {
                ...state,
                tractors: state.allTractors,
                realTimes: state.allRealTimes,
                filtered: false,
                filterModels: null,
                filterMakers: null,
                filterNames: null,
            }
        default:
            return state
    }
}

export default loginReducer
