import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import ForumIcon from "@material-ui/icons/Forum";

const useStyles = makeStyles((theme) => ({
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  nortification: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100vh",
    background: "#FFFFFF",
    outline: "none",
    border: "none",
  },
  nortificationHeader: {
    width: "100%",
    height: "60px",
    background: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#FFFFFF",
    position: "fixed",
    padding: "0 1.3em",
    "& > p": {
      cursor: "pointer",
    },
  },
  cross: {
    position: "absolute",
    left: "20%",
  },
  card: {
    height: "30px",
    margin: "30px",
  },
  tractor: {
    color: "black",
  },
}));

function Category({
  setNortificationOpen,
  Close,
  nortificationOpen,
  setSelected,
  setNotificationValue,
  tractorName,
  index,
  ...props
}) {
  const classes = useStyles();
  const [counter1, setCounter1] = useState(0);
  const [counter2, setCounter2] = useState(0);

  useEffect(() => {
    let count1 = 0;
    let count2 = 0;
    props.notify.forEach((val) => {
      if (
        val.category === "Low Fuel Alert" ||
        val.category === "Refuel Alert"
      ) {
        count1 = count1 + 1;
      } else if (val.category === "Other Alert") {
        count2 = count2 + 1;
      }
    });
    setCounter1(count1);
    setCounter2(count2);
  }, [props.notify]);

  const item = [
    {
      title: "SECURITY NOTIFICATIONS",
      subtitle: "Notification related to your trafic security",
      filter: "",
    },
    {
      title: "HEALTH NOTIFICATIONS",
      subtitle: "Notification related to your Tractor Health",
      filter: "",
    },
    {
      title: "ACTIVITY NOTIFICATIONS",
      subtitle: "Notification related to your Tractor Activity",
      filter: "activity",
    },
    {
      title: "SUBSCRIPTION NOTIFICATIONS",
      subtitle: "Notification related to Simha Kit Subscription",
      filter: "",
    },
    {
      title: "OTHER NOTIFICATIONS",
      subtitle: "Other Generic notifications",
      filter: "other",
    },
  ];
  return (
    <>
      <div className={classes.nortificationHeader}>
        <p>Alerts ({tractorName})</p>
        <p
          className={classes.cross}
          onClick={() => setNortificationOpen(!nortificationOpen)}
        >
          <img src={Close} alt="close" /> Close
        </p>
      </div>
      <div
        style={{ scrollPaddingTop: "80px", margin: "60px auto" }}
        className={classes.column}
      >
        {item.map((val, key) => {
          return (
            <Card
              key={key}
              onClick={() => {
                setSelected(true);
                setNotificationValue(val.filter);
              }}
              style={{
                cursor: "pointer",
                margin: "30px",
                height: "80px",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                border: "groove",
                boxShadow: "3px 4px #888888",
              }}
            >
              <ForumIcon style={{ color: "#BE1E2D", margin: "14px" }} />
              <div style={{ fontSize: "12px" }}>
                <p style={{ fontSize: "14px" }}>
                  {val.title}{" "}
                  <b>
                    (
                    {val.title === "ACTIVITY NOTIFICATIONS"
                      ? counter1
                      : val.title === "OTHER NOTIFICATIONS"
                      ? counter2
                      : "0"}
                    )
                  </b>
                </p>
                <p>{val.subtitle}</p>
              </div>
            </Card>
          );
        })}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  notify: state.fetchNotificationReducer.data,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Category);
