import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import Sidebar from '../../components/sidebar'

const Dashboard = ({ tractors, realtimeList }) => {
    const [show, setShow] = useState(true)
    const [selectedTractor, setSelectedTractor] = useState(null)

    const [position, setposition] = useState(0)
    const [showTrips, setshowTrips] = useState(false)

    const onSelectedTractorChanged = (newPostion) => {
        setposition(newPostion)
    }

    useEffect(() => {
        setSelectedTractor({
            tractor: tractors[0],
            index: 0,
            realTime:
                realtimeList.find((rt) => rt.fb_key === tractors[0].fb_key) ??
                realtimeList[0],
        })
    }, [tractors, realtimeList])

    const onShowTrips = (hideShow) => {
        setshowTrips(hideShow)
    }

    return (
        <div>
            <Navbar
                tractors={tractors}
                realtimeList={realtimeList}
                onPositionChange={onSelectedTractorChanged}
                setSelectedTractor={setSelectedTractor}
                onShowTrips={onShowTrips}
                setShow={setShow}
                show={show}
            />
            <Sidebar
                tractors={tractors}
                realtimeList={realtimeList}
                show={show}
                setShow={setShow}
                selectedTractor={selectedTractor}
                setSelectedTractor={setSelectedTractor}
                position={position}
                setposition={setposition}
                showTrips={showTrips}
                setshowTrips={setshowTrips}
                onSelectedTractorChanged={onSelectedTractorChanged}
                onShowTrips={onShowTrips}
            />
        </div>
    )
}

export default Dashboard
