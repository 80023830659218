import React from "react";
import { makeStyles, withTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  btn: {
    outline: "none",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "4px",
    padding: "1em",
    gap: "10px",
    transition: ".5s",
    "&:hover": {
      // boxShadow: "0px 5px 15px rgb(116 0 0 / 0.3)",
      // background: "rgb(227 29 48)"
    },
  },
  btnFilled: {},
  btnOutlined: {
    border: `2px solid ${theme.palette.primary.button}`,
  },
  disabled: {
    cursor: "not-allowed",
  },
  notDisabled: {
    cursor: "pointer",
  },
}));

function Button({ children, ...props }) {
  const classes = useStyles();
  return (
    <button
      className={`${classes.btn} ${
        props.disabled ? classes.disabled : classes.notDisabled
      }`}
      type={props.type ? props.type : "button"}
      style={{
        width: `${props.width ? props.width : "auto"}`,
        height: `${props.height ? props.height : "45px"}`,
        background: `${
          props.background ? props.background : props.theme.palette.primary.button
        }`,
        color: `${props.txtColor ? props.txtColor : "#FFFFFF"}`,
      }}
      onClick={props.onClick ? props.onClick : undefined}
      disabled={props.disabled ? true : false}
    >
      {children}
    </button>
  );
}

const OutlinedButton = withTheme(({ children, ...props }) => {
  const classes = useStyles();

  return (
    <button
      type={props.type ? props.type : "button"}
      className={`${classes.btn} ${classes.btnOutlined} ${
        props.disabled ? classes.disabled : classes.notDisabled
      }`}
      style={{
        width: `${props.width ? props.width : "auto"}`,
        height: `${props.height ? props.height : "45px"}`,
        background: `${props.theme.palette.secondary.main}`,
        color: `${props.theme.palette.primary.button}`,
      }}
      onClick={props.onClick ? props.onClick : undefined}
      disabled={props.disabled ? true : false}
    >
      {children}
    </button>
  );
});

export { OutlinedButton };

export default withTheme(Button);
