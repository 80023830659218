import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles((theme) => ({
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  nortification: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100vh",
    background: "#FFFFFF",
    outline: "none",
    border: "none",
  },
  nortificationHeader: {
    width: "100%",
    height: "60px",
    background: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#FFFFFF",
    position: "fixed",

    padding: "0 1.3em",
    "& > p": {
      cursor: "pointer",
    },
  },
  cross: {
    position: "absolute",
    left: "20%",
  },
  card: {
    height: "30px",
    margin: "30px",
  },
}));

function List({
  setNortificationOpen,
  Close,
  setSelected,
  nortificationOpen,
  notificationValue,
  tractorName,
  ...props
}) {
  const classes = useStyles();
  const [notification, setNotification] = useState([]);

  useEffect(() => {
    let data = props.notify.reduce((acc, val, index) => {
      if (
        notificationValue === "activity" &&
        (val.category === "Low Fuel Alert" || val.category === "Refuel Alert")
      ) {
        acc = [...acc, val];
      } else if (
        val.category === "Other Alert" &&
        notificationValue === "other"
      ) {
        acc = [...acc, val];
      }
      return acc;
    }, []);
    setNotification(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.notify]);

  return (
    <>
      <div className={classes.nortificationHeader}>
        <p>Alerts ({tractorName})</p>
        <p
          className={classes.cross}
          onClick={() => {
            setNortificationOpen(!nortificationOpen);
            setSelected(false);
          }}
        >
          <img src={Close} alt="close" /> Close
        </p>
      </div>
      <div style={{ scrollPaddingTop: "80px", margin: "80px auto" }}>
        <p
          onClick={() => setSelected(false)}
          style={{ cursor: "pointer", padding: "0.5em 1em" }}
        >
          Back to Category
        </p>
        {notification.map((item) => {
          return (
            <Card
              style={{
                cursor: "pointer",
                margin: "10px",
                height: "80px",
                display: "flex",
                alignItems: "start",
                justifyContent: "space-around",
                border: "groove",
                boxShadow: "3px 4px #888888",
                flexDirection: "column",
              }}
            >
              <div>{item?.message}</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div> {item?.time_stamp.split(" ")[1]}</div>
                <div>
                  {" "}
                  {item?.time_stamp
                    .split(" ")[0]
                    .split("-")
                    .reverse()
                    .join("-")}
                </div>
              </div>
            </Card>
          );
        })}
        {notification?.length === 0 && (
          <p style={{ marginLeft: "20px" }}>
            THERE ARE NO MESSAGES AVAILABLE CURRENTLY
          </p>
        )}
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  notify: state.fetchNotificationReducer.data,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(List);
