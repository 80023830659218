import * as types from '../types'

const initialState = {
    loading: false,
    requestEditGeofence: null,
    resEditGeofence: null,
    error: null,
}

export default function editGeofenceReducer(state = initialState, action) {
    switch (action.type) {
        case types.EDIT_GEOFENCE_REQUEST:
            return {
                ...state,
                loading: true,
                requestEditGeofence: action.payload.requestEditGeofence,
            }

        case types.EDIT_GEOFENCE_SUCCESS:
            return {
                ...state,
                loading: false,
                resEditGeofence: action.payload.resEditGeofence,
            }

        case types.EDIT_GEOFENCE_FAILURE:
            return {
                ...state,
                loading: false,
                requestEditGeofence: null,
                resEditGeofence: null,
                error: action.payload.error,
            }

        default:
            return state
    }
}
