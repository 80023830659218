import React from 'react'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import { otpRequest } from '../../store/actions/otpRequestAction'
import { loginRequest } from '../../store/actions/loginAction'

import './login.css'
import Logo from '../../assets/Krish-e-Purple.png'
import Person from '../../assets/login/person.svg'
import PersonAdd from '../../assets/login/person_add.svg'
import Arrow from '../../assets/login/Long-arrow.svg'
import Support from '../../assets/login/support_agent.svg'
import { Input, Button } from '../../elements'
import Spinner from '../../assets/spinner.svg'

import OtpInput from 'react-otp-input'

const Login = (props) => {
    return (
        <div className="root">
            <div className="rootLeft-wrapper">
                <div className="rootLeft">
                    <div className="logo">
                        <img
                            src={Logo}
                            alt="krishe-smart-kit logo"
                            width="200px"
                            height="80px"
                        />
                    </div>
                    <div className="intro">
                        <h2>Welcome Back</h2>
                        <p>Thank you for joining Krish-e Family</p>
                    </div>
                    <Formik
                        initialValues={{ phone: '', otp: '' }}
                        validate={(values) => {
                            const errors = {}
                            if (!values.phone) {
                                errors.phone = '*Phone number is Required!'
                            } else if (!/^\d{10}$/.test(values.phone)) {
                                errors.phone = 'Invalid Phone number'
                            }
                            if (props.otpSent && values.otp === '') {
                                errors.otp = '*Please Enter OTP!'
                            }
                            return errors
                        }}
                        onSubmit={(values) => {
                            if (!props.otpSent) {
                                props.makeOtpRequest(values.phone)
                            } else {
                                props.makeLoginRequest({
                                    phone: values.phone,
                                    otp: values.otp.toString(),
                                })
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <h3>Log In</h3>
                                <Input
                                    type="text"
                                    icon={Person}
                                    name="phone"
                                    placeholder="Phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    width="400px"
                                    error={touched.phone && errors.phone}
                                />
                                {values.phone &&
                                    values.phone.length === 10 &&
                                    values.phone === props.otpNumber &&
                                    props.otpResponse &&
                                    !props.otpSent && (
                                        <span className="numberErrorMsg">
                                            You are not registered User
                                        </span>
                                    )}
                                {props.otpSent && (
                                    <div className="otpBox">
                                        <p>Enter OTP: </p>
                                        <OtpInput
                                            id="otp"
                                            name="otp"
                                            value={values.otp}
                                            onChange={(otp) => {
                                                setFieldValue('otp', otp, true)
                                            }}
                                            numInputs={4}
                                            isInputNum={true}
                                            isInputSecure={true}
                                            inputStyle="otpInput"
                                            focusStyle="otpFous"
                                            containerStyle="otpContainer"
                                            errorStyle="otpError"
                                            shouldAutoFocus={true}
                                            hasErrored={errors.otp}
                                            separator={
                                                <span className="otpSaperator">
                                                    *
                                                </span>
                                            }
                                        />
                                        {errors.otp && (
                                            <span className="otpErrorMsg">
                                                {errors.otp}
                                            </span>
                                        )}
                                        {props.otpSent &&
                                            props.errorLogin?.hasOwnProperty(
                                                'message'
                                            ) && (
                                                <span className="otpErrorMsg">
                                                    OTP is incorrect
                                                </span>
                                            )}
                                    </div>
                                )}
                                <Button type="submit" width="400px">
                                    <p>
                                        {!props.loadingOtp && props.otpSent
                                            ? 'Start Managing Tractors'
                                            : 'Send OTP'}
                                    </p>
                                    <span>
                                        {props.loadingOtp ||
                                        props.loadingLogin ? (
                                            <img
                                                src={Spinner}
                                                alt="loading.."
                                                width="25px"
                                                height="25px"
                                            />
                                        ) : (
                                            <img src={Arrow} alt="arrow" />
                                        )}
                                    </span>
                                </Button>
                            </form>
                        )}
                    </Formik>

                    <div className="helpline">
                        <div className="helpline-signup">
                            <span>
                                <img src={PersonAdd} alt="person add" />
                            </span>
                            <p>Don't have an Account?</p>
                            <a href="!#">Request an Account</a>
                        </div>

                        <div className="helpline-support">
                            <span>
                                <img src={Support} alt="support" />
                            </span>
                            <p>Trouble Logging in?</p>
                            <a href="tel:+91-966-783-4835">Call Support</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rootRight"></div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    otpSent: state.otpRequestReducer.otpSent,
    otpResponse: state.otpRequestReducer.otpSuccess,
    otpNumber: state.otpRequestReducer.phone,
    loadingOtp: state.otpRequestReducer.loading,
    errorOtp: state.otpRequestReducer.error,
    tractors: state.loginReducer.tractors,
    isLoggedIn: state.loginReducer.isLoggedIn,
    loadingLogin: state.loginReducer.loading,
    errorLogin: state.loginReducer.error,
})

const mapDispatchToProps = (dispatch) => ({
    makeOtpRequest: (phone) => {
        dispatch(otpRequest(phone))
    },
    makeLoginRequest: ({ phone, otp }) => {
        dispatch(loginRequest({ phone, otp }))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
